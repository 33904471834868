import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Group from 'components/utils/Group'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import { FlightDealsCarouselFilterItem } from './FlightDealsCarouselFilterItem'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import useToggle from 'hooks/useToggle'
import { ALL_KEY, FilterType } from './FlightDealsCarousel'
import { fareCabinLabelMap, FlightsClassTypes } from 'constants/flight'

const FiltersContainer = styled(Group)`
  padding: 0 ${rem(16)};
`

interface Filter {
  label: string;
  value: string;
  price?: number;
}

interface Props {
  airlines: Array<Filter>;
  cabins: Array<Filter>;
  selectedAirlines: Set<string>;
  selectedCabins: Set<string>;
  handleToggle: (type: FilterType, value: string) => void;
}

export function FlightDealsCarouselStaticFilterPills(props: Props) {
  const { airlines, cabins, selectedAirlines, selectedCabins, handleToggle } = props
  const airlinesDropdownTrigger = useRef<HTMLButtonElement>(null)
  const cabinsDropdownTrigger = useRef<HTMLButtonElement>(null)
  const { value: showAirlinesFilter, toggle: toggleAirlinesFilter } = useToggle()
  const { value: showCabinsFilter, toggle: toggleCabinsFilter } = useToggle()

  const showAirlinesNum = selectedAirlines.size > 0 && !selectedAirlines.has(ALL_KEY)
  const showCabinsNum = selectedCabins.size > 0 && !selectedCabins.has(ALL_KEY)

  return (
    <>
      <FilterChip
        size="medium"
        ref={airlinesDropdownTrigger}
        onClick={toggleAirlinesFilter}
        endIcon={showAirlinesFilter ? <LineAngleUpIcon /> : <LineAngleDownIcon />}
      >
        {!showAirlinesNum ? 'All airlines' : 'Airlines'} {showAirlinesNum && `(${selectedAirlines.size})`}
      </FilterChip>

      <FilterChip
        size="medium"
        ref={cabinsDropdownTrigger}
        endIcon={showAirlinesFilter ? <LineAngleUpIcon /> : <LineAngleDownIcon />}
        onClick={toggleCabinsFilter}
      >
        {!showCabinsNum ? 'All cabins' : 'Cabins'} {showCabinsNum && `(${selectedCabins.size})`}
      </FilterChip>

      <DropdownList
        size="M"
        open={showAirlinesFilter}
        triggerRef={airlinesDropdownTrigger}
        anchorRef={airlinesDropdownTrigger}
        onClose={toggleAirlinesFilter}
        placement="bottom-start"
      >
        <FiltersContainer direction="vertical">
          <VerticalSpacer gap={16}>
            <FlightDealsCarouselFilterItem
              checked={selectedAirlines.has(ALL_KEY)}
              onCheckedChange={() => handleToggle(FilterType.AIRLINES, ALL_KEY)}
              label="All airlines"
            />

            {airlines.map(airline => (
              <FlightDealsCarouselFilterItem
                key={airline.value}
                checked={selectedAirlines.has(airline.value)}
                onCheckedChange={() => handleToggle(FilterType.AIRLINES, airline.value)}
                label={airline.label}
                price={airline.price}
              />
            ))}
          </VerticalSpacer>
        </FiltersContainer>
      </DropdownList>

      <DropdownList
        size="M"
        open={showCabinsFilter}
        triggerRef={cabinsDropdownTrigger}
        anchorRef={cabinsDropdownTrigger}
        onClose={toggleCabinsFilter}
        placement="bottom-start"
      >
        <FiltersContainer direction="vertical">
          <VerticalSpacer gap={16}>
            <FlightDealsCarouselFilterItem
              checked={selectedCabins.has(ALL_KEY)}
              onCheckedChange={() => handleToggle(FilterType.CABINS, ALL_KEY)}
              label="All cabins"
            />

            {cabins.map(cabin => (
              <FlightDealsCarouselFilterItem
                key={cabin.value}
                checked={selectedCabins.has(cabin.value)}
                onCheckedChange={() => handleToggle(FilterType.CABINS, cabin.value)}
                label={fareCabinLabelMap[cabin.value as FlightsClassTypes]}
                price={cabin.price}
              />
            ))}
          </VerticalSpacer>
        </FiltersContainer>
      </DropdownList>
    </>
  )
}
