import React from 'react'
import Group from 'components/utils/Group'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import BodyText from 'components/Luxkit/Typography/BodyText'
import FormatCurrency from 'components/Common/FormatCurrency'

interface Props {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label: string;
  price?: number;
}

export function FlightDealsCarouselFilterItem(props: Props) {
  const { checked, onCheckedChange, label, price } = props

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap="0 8">
      <Group direction="horizontal" verticalAlign="center" gap="0 8">
        <CheckboxInput
          key="checkAll"
          name="All"
          value="All"
          size="large"
          checked={checked}
          onChange={e => onCheckedChange(e.target.checked)}
        >
          {label}
        </CheckboxInput>
      </Group>

      {price && <BodyText variant="medium">
        <FormatCurrency value={price} />
      </BodyText>}
    </Group>
  )
}
