import React, { useMemo } from 'react'
import { getFlightLoyaltyProductType } from 'lib/flights/flightUtils'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'

interface Props {
  flightDeal: App.FlightDeal;
}

function FlightDealPoints({ flightDeal }: Props) {
  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest>>(() => {
    const isDomestic = flightDeal.originCountry === flightDeal.destinationCountry
    const type = getFlightLoyaltyProductType(flightDeal.fareClass.toLowerCase() || '', isDomestic)

    return [{
      luxLoyaltyProductType: type,
      price: flightDeal.perAdultPrice,
    }]
  }, [flightDeal])

  return (
    <LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />
  )
}

export default FlightDealPoints
