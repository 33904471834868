import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import Heading from 'components/Luxkit/Typography/Heading'
import LayoutContainer from 'components/Common/LayoutContainer'
import { MAX_CAROUSEL_ITEM_COUNT } from '../../OfferCarousels/common/constants'
import { sortBy, take, uniqueBy } from 'lib/array/arrayUtils'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FlightDealAirportSelect from '../FlightDealAirportSelect'
import Group from 'components/utils/Group'
import FlightDealTileSkeletonLoader from '../FlightDealSkeletonLoader'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import { getListName } from 'analytics/enhanced-ecommerce'
import useOfferListTracking from 'hooks/Offers/useOfferListTracking'
import FlightDealImageTile from 'components/Flights/FlightMerchandising/FlightDealImageTile'
import styled from 'styled-components'
import { rem } from 'polished'
import FullWidthCarousel from 'components/Luxkit/Carousel/FullWidthCarousel'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { FlightDealsCarouselStaticFilterPills } from './FlightDealsCarouselStaticFilterPills'
import Divider from 'components/Luxkit/Divider'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import useToggle from 'hooks/useToggle'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import { FlightDealsCarouselFilterItem } from './FlightDealsCarouselFilterItem'
import { fareCabinLabelMap, FlightsClassTypes } from 'constants/flight'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import FormatCurrency from 'components/Common/FormatCurrency'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'

const FiltersContainer = styled(Group)`
  padding: 0 ${rem(16)};
`

const StyledFlightDealTileSkeletonLoader = styled(FlightDealTileSkeletonLoader)`
  width: ${rem(275)};
`

const DividerContainer = styled.div`
  height: ${rem(16)};
  padding: 0 ${rem(4)};
`

const DividerBar = styled(Divider)`
  height: ${rem(16)};
  max-height: ${rem(16)};
`

const LookingForSomethingElseContainer = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(12)} ${rem(20)};
  width: ${rem(275)};


  ${mediaQueryUp.tablet} {
    padding: ${rem(24)} ${rem(40)};
  }
`

const Content = styled(Group)`
  text-align: center;
`

export enum FilterType {
  DESTINATIONS = 'destinations',
  AIRLINES = 'airlines',
  CABINS = 'cabins'
}

type FilterState = Record<FilterType, Set<string>>

enum FilterActionType {
  TOGGLE_FILTER = 'TOGGLE_FILTER',
  SET_FILTER_VALUE = 'SET_FILTER_VALUE',
  RESET_FILTERS = 'RESET_FILTERS',
}

type FilterAction = {
  type: FilterActionType.TOGGLE_FILTER,
  payload: {
    type: FilterType,
    value: string
  }
} | {
  type: FilterActionType.SET_FILTER_VALUE,
  payload: {
    type: FilterType,
    value: Set<string>
  }
} | {
  type: FilterActionType.RESET_FILTERS,
}

export const ALL_KEY = 'all'

const initialState: FilterState = {
  destinations: new Set([ALL_KEY]),
  airlines: new Set([ALL_KEY]),
  cabins: new Set([ALL_KEY]),
}

function reducer(state: FilterState, action: FilterAction): FilterState {
  if (action.type === FilterActionType.TOGGLE_FILTER) {
    let newData = new Set([...state[action.payload.type]])

    if (action.payload.value === ALL_KEY) {
      const deleted = newData.delete(action.payload.value)
      newData = deleted ? new Set() : new Set([ALL_KEY])
    } else {
      newData.delete(ALL_KEY)
      const deleted = newData.delete(action.payload.value)
      if (!deleted) newData.add(action.payload.value)
    }

    if (newData.size === 0) {
      newData.add(ALL_KEY)
    }

    return {
      ...state,
      [action.payload.type]: newData,
    }
  }

  if (action.type === FilterActionType.SET_FILTER_VALUE) {
    return {
      ...state,
      [action.payload.type]: action.payload.value,
    }
  }

  if (action.type === FilterActionType.RESET_FILTERS) {
    return {
      ...initialState,
    }
  }

  return state
}

interface Props {
  title: string;
  showViewAll?: boolean;
  airports: Array<App.Airport>;
  selectedAirport?: App.Airport;
  defaultAirport?: App.Airport;
  flightDeals: Array<App.FlightDeal>;
  onAirportChange: (airport: App.Airport) => void;
  loading?: boolean;
  showLookingForAFlight?: boolean;
}

function FlightDealsCarousel(props: Props) {
  const { title, showViewAll, airports, selectedAirport, flightDeals, defaultAirport, onAirportChange, loading, showLookingForAFlight } = props
  const [filtersState, filtersDispatch] = useReducer(reducer, initialState)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const airlinesDropdownTrigger = useRef<HTMLButtonElement>(null)
  const cabinsDropdownTrigger = useRef<HTMLButtonElement>(null)
  const { value: showAirlinesFilter, toggle: toggleAirlinesFilter } = useToggle()
  const { value: showCabinsFilter, toggle: toggleCabinsFilter } = useToggle()
  const airport = selectedAirport ?? defaultAirport
  const showLookingForSomethingElse = showLookingForAFlight && filtersState.destinations.has(ALL_KEY)

  useEffect(() => {
    carouselRef.current?.scrollTo(0, 0)
  }, [filtersState])

  const currentAirportDeals = useMemo(() => {
    if (!airport) return []

    const deals = flightDeals.filter(({ originAirportCode }) => originAirportCode === airport.code)
    return sortBy(deals, (deal) => deal.hierarchy, 'asc')
  }, [airport, flightDeals])

  useEffect(() => {
    filtersDispatch({ type: FilterActionType.RESET_FILTERS })
  }, [airport])

  const destinations = useMemo(() => {
    const countries = currentAirportDeals.filter(deal => {
      const airlineIncluded = filtersState.airlines.has(ALL_KEY) || filtersState.airlines.has(deal.carrier.code)
      const cabinIncluded = filtersState.cabins.has(ALL_KEY) || filtersState.cabins.has(deal.fareClass)
      return airlineIncluded && cabinIncluded
    }).map(deal => ({
      label: deal.destinationCountry,
      value: deal.destinationCountry,
      price: deal.perAdultPrice,
    }))

    const sortedCities = sortBy(countries, (country) => country.price, 'asc')
    return uniqueBy(sortedCities, country => country.label)
  }, [currentAirportDeals, filtersState.airlines, filtersState.cabins])

  const airlines = useMemo(() => {
    const carriers = currentAirportDeals.map(deal => ({
      label: deal.carrier.name,
      value: deal.carrier.code,
      price: deal.perAdultPrice,
    }))

    const sortedCarriers = sortBy(carriers, (carrier) => carrier.price, 'asc')
    return uniqueBy(sortedCarriers, carrier => carrier.value)
  }, [currentAirportDeals])

  const cabins = useMemo(() => {
    const data = currentAirportDeals
      .filter(deal => {
        const airlineIncluded = filtersState.airlines.has(deal.carrier.code) || filtersState.airlines.has(ALL_KEY)
        const destinationIncluded = filtersState.destinations.has(deal.destinationCountry) || filtersState.destinations.has(ALL_KEY)
        return airlineIncluded && destinationIncluded
      })
      .map(deal => ({
        label: deal.fareClass,
        value: deal.fareClass as FlightsClassTypes,
        price: deal.perAdultPrice,
      }))

    const sortedCabins = sortBy(data, (data) => data.price, 'asc')
    return uniqueBy(sortedCabins, cabin => cabin.value)
  }, [currentAirportDeals, filtersState.airlines, filtersState.destinations])

  const handleToggleFilterValue = useCallback((type: FilterType, value: string) => {
    filtersDispatch({
      type: FilterActionType.TOGGLE_FILTER,
      payload: {
        type,
        value,
      },
    })

    if (type === FilterType.AIRLINES) {
      filtersDispatch({
        type: FilterActionType.SET_FILTER_VALUE,
        payload: {
          type: FilterType.CABINS,
          value: new Set([ALL_KEY]),
        },
      })

      filtersDispatch({
        type: FilterActionType.SET_FILTER_VALUE,
        payload: {
          type: FilterType.DESTINATIONS,
          value: new Set([ALL_KEY]),
        },
      })
    }

    if (type === FilterType.CABINS) {
      filtersDispatch({
        type: FilterActionType.SET_FILTER_VALUE,
        payload: {
          type: FilterType.DESTINATIONS,
          value: new Set([ALL_KEY]),
        },
      })
    }
  }, [])

  const filteredDeals = useMemo(() => {
    return currentAirportDeals.filter(deal => {
      let show = true

      if (filtersState.destinations.size && !filtersState.destinations.has(ALL_KEY)) {
        show = show && filtersState.destinations.has(deal.destinationCountry)
      }

      if (filtersState.airlines.size && !filtersState.airlines.has(ALL_KEY)) {
        show = show && filtersState.airlines.has(deal.carrier.code)
      }

      if (filtersState.cabins.size && !filtersState.cabins.has(ALL_KEY)) {
        show = show && filtersState.cabins.has(deal.fareClass)
      }

      return show
    })
  }, [currentAirportDeals, filtersState])

  const cheapestDestination = useMemo(() => {
    return sortBy(destinations, val => val.price, 'asc')[0]?.price
  }, [destinations])

  const tracking = useOfferListTracking(
    getListName('OfferList', 'FLIGHTS', 'Flights Best Flights'),
  )

  return (
    <>
      <VerticalSpacer gap={32}>
        <LayoutContainer>
          <VerticalSpacer gap={32}>
            <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap={16}>
              <Group direction="vertical" tabletDirection="horizontal" tabletGap={8}>
                <Heading variant="heading2">{title}</Heading>
                {airport && airports.length > 1 &&
                  <FlightDealAirportSelect
                    selected={airport}
                    airports={airports}
                    onChange={onAirportChange}
                  />
                }
              </Group>

              {showViewAll && <TextButton kind="tertiary" size="medium" to="/flights">
                View all
              </TextButton>}
            </Group>

            {flightDeals.length > 0 && (
              <CardCarousel gap={4}>
                <FlightDealsCarouselStaticFilterPills
                  airlines={airlines}
                  cabins={cabins}
                  selectedAirlines={filtersState.airlines}
                  selectedCabins={filtersState.cabins}
                  handleToggle={handleToggleFilterValue}
                />

                <Group direction="vertical" verticalAlign="center">
                  <DividerContainer>
                    <DividerBar kind="primary" direction="vertical" />
                  </DividerContainer>
                </Group>

                {destinations.length > 0 && <FilterChip
                  size="medium"
                  onClick={() => handleToggleFilterValue(FilterType.DESTINATIONS, ALL_KEY)}
                  selected={filtersState.destinations.has(ALL_KEY)}
                >
                  <b>All destinations</b> <FormatCurrency value={cheapestDestination} />*
                </FilterChip>}

                {destinations.map(destination => (
                  <FilterChip
                    key={destination.value}
                    size="medium"
                    onClick={() => handleToggleFilterValue(FilterType.DESTINATIONS, destination.value)}
                    selected={filtersState.destinations.has(destination.label)}
                  >
                    <b>{destination.label}</b> <FormatCurrency value={destination.price} />*
                  </FilterChip>
                ))}
              </CardCarousel>
            )}
          </VerticalSpacer>
        </LayoutContainer>

        <FullWidthCarousel
          snap="start"
          itemsPerArrow={4}
          ref={carouselRef}
        >
          {loading && <>
            <StyledFlightDealTileSkeletonLoader />
            <StyledFlightDealTileSkeletonLoader />
            <StyledFlightDealTileSkeletonLoader />
          </>}

          {!loading && <OfferListEventsProvider tracking={tracking}>
            {take(filteredDeals, MAX_CAROUSEL_ITEM_COUNT).map((flightDeal, index) => (
              <FlightDealImageTile key={flightDeal.id} position={index} flightDeal={flightDeal} hasMultipleAirlines />
            ))}

            {showLookingForSomethingElse && <LookingForSomethingElseContainer
              gap={16}
              direction="vertical"
              horizontalAlign="center"
              verticalAlign="center"
            >
              <Content direction="vertical" gap={8} verticalAlign="center">
                <BodyText variant="large" weight="bold">Looking for a flight?</BodyText>
                <BodyText variant="medium">Search for all destinations for more amazing offers</BodyText>
              </Content>

              <TextButton kind="secondary" size="medium" shape="square" to="/flights">
                Search now
              </TextButton>
            </LookingForSomethingElseContainer>}
          </OfferListEventsProvider>}
        </FullWidthCarousel>

        <LayoutContainer>
          <BodyTextBlock variant="small">
            *Starting price of the current campaigns
          </BodyTextBlock>
        </LayoutContainer>
      </VerticalSpacer>

      <DropdownList
        size="M"
        open={showAirlinesFilter}
        triggerRef={airlinesDropdownTrigger}
        anchorRef={airlinesDropdownTrigger}
        onClose={toggleAirlinesFilter}
        placement="bottom-start"
      >
        <FiltersContainer direction="vertical">
          <VerticalSpacer gap={16}>
            <FlightDealsCarouselFilterItem
              checked={filtersState.airlines.has(ALL_KEY)}
              onCheckedChange={() => handleToggleFilterValue(FilterType.AIRLINES, ALL_KEY)}
              label="All airlines"
            />

            {airlines.map(airline => (
              <FlightDealsCarouselFilterItem
                key={airline.value}
                checked={filtersState.airlines.has(airline.value)}
                onCheckedChange={() => handleToggleFilterValue(FilterType.AIRLINES, airline.value)}
                label={airline.label}
                price={airline.price}
              />
            ))}
          </VerticalSpacer>
        </FiltersContainer>
      </DropdownList>

      <DropdownList
        size="M"
        open={showCabinsFilter}
        triggerRef={cabinsDropdownTrigger}
        anchorRef={cabinsDropdownTrigger}
        onClose={toggleCabinsFilter}
        placement="bottom-start"
      >
        <FiltersContainer direction="vertical">
          <VerticalSpacer gap={16}>
            <FlightDealsCarouselFilterItem
              checked={filtersState.cabins.has(ALL_KEY)}
              onCheckedChange={() => handleToggleFilterValue(FilterType.CABINS, ALL_KEY)}
              label="All cabins"
            />

            {cabins.map(cabin => (
              <FlightDealsCarouselFilterItem
                key={cabin.value}
                checked={filtersState.cabins.has(cabin.value)}
                onCheckedChange={() => handleToggleFilterValue(FilterType.CABINS, cabin.value)}
                label={fareCabinLabelMap[cabin.value]}
                price={cabin.price}
              />
            ))}
          </VerticalSpacer>
        </FiltersContainer>
      </DropdownList>
    </>
  )
}

export default FlightDealsCarousel
